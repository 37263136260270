.exce-payment-page {
  min-height: 130vh;
  height: 100%;
  background-color: black;
  box-sizing: border-box;

  @media (min-width:350px) and (max-width:640px) {
    min-height: 110vh;
  }

  .main-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .banner {
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../images/registration-banner.jpg");
    width: 100%;
    opacity: 0.5;
  }

  .reg-box {
    max-width: 600px;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 5rem;
    border-radius: 12px;

    @media (min-width:350px) {
      width: 90%;
    }

  }

  .status-box {
    padding: 2rem 2rem;
    border-bottom: 2px dashed gray;

    @media (min-width:350px) and (max-width:640px) {
      padding: 2rem 1rem !important;
    }

    &__inner {
      display: flex;
      width: 100%;
      gap: 1.5rem;
      justify-content: space-around;
      align-items: flex-start;

      .status-box-left-icon {
        width: 50px !important;

        img {
          padding-top: 0.5rem;

          @media (min-width:350px) and (max-width:640px) {
            padding-top: 0rem;
          }
        }
      }

      .status-success {
        color: #2FAB23;
      }

      .status-failed {
        color: red;
      }

      .status-box-right-section {
        width: 80%;

        .status-box-title {
          font-size: 40px;
          font-weight: 600;

          @media (min-width:350px) and (max-width:640px) {
            font-size: 20px;
          }

        }

        .status-box-desc {
          font-size: 20px;

          @media (min-width:350px) and (max-width:640px) {
            font-size: 14px;
          }
        }
      }
    }

  }

  .qr-detail-box {
    padding: 2rem 2rem;
    border-bottom: 2px dashed gray;

    &__inner {
      display: flex;
      width: 100%;
      gap: 1.5rem;
      justify-content: space-between;
      align-items: center;

      .qr-detail-event-title {
        width: 50%;
        font-size: 24px;
        font-weight: 600;
      }

      img {
        @media (min-width:350px) and (max-width:640px) {
          width: 45% !important;
        }
      }

    }
  }

  .events-detail-box {
    padding: 2rem 2rem;

    &__inner {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .event-detail-heading {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 4px;
        color: gray;
      }

      .event-detail-desc {
        color: black;
        font-size: 20px;
        font-weight: 600;

        @media (min-width:350px) and (max-width:640px) {
          font-size: 16px;
        }
      }

      .event-detail-date-time-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .event-detail-btn {
    padding-top: 2rem;
    width: 100%;

    button {
      padding: 0.8rem;
      width: 100%;
      border-radius: 100px;
      border: none;
      background-color: black;
      color: white;
      font-weight: 500;
    }

    button:hover {
      background-color: transparent;
      outline: 1px solid black;
      color: black;
    }
  }

  .outer-btn-continue {
    padding: 2rem !important;
  }
}