.exce-news-page {
  .banner-title {
    @media (min-width:350px) and (max-width:1024px) {
      font-size: 40px !important;
    }
  }

  .bh-event-card {
    @media (min-width:350px) and (max-width:1024px) {
      height: 470px;
    }

    .bh-event-image {
      @media (min-width:350px) and (max-width:1024px) {
        height: 300px;
      }

      img {
        height: 100%;
        object-fit: cover;
      }

    }

    .event-body-content {
      @media (min-width:350px) and (max-width:1024px) {
        height: 200px;
      }
    }


  }

  .exce-news-card-col {
    @media (min-width:768px) and (max-width:1024px) {
      width: 50%;
    }
  }
}