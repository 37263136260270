// Import SCSS Variables
@import "variables";
@import "mixins";

.page-banner {
    &.training-banner-1 {
        background-image: url('../images/training/training-banner.jpg');
    }

    &.training-v2 {
        background-image: url('../images/training/training-banner-2.jpg');
    }
}

.corporate-training {
    .corporate-box {
        .event-image {
            border-radius: 18px;
            overflow: hidden;

            .event-desc-img {
                max-width: 889px;

                .event-sub-title {
                    font-size: $fs-60;
                    font-weight: $bold;
                    line-height: 63px;
                    margin-bottom: 20px;
                }

                p {
                    font-size: $fs-20;
                    font-weight: $medium;
                    margin-bottom: 25px !important;
                }
            }
        }

    }

}

.experience {
    .experience-block {
        position: relative;
        overflow: hidden;
        border-radius: 6px;

        .experience-content {
            @include flex(row, start, center, wrap);
            border-radius: 6px;
            background-color: $white-opacity-10;
            backdrop-filter: blur(10px);
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: 15px;
            height: 90px;
            padding: 15px;
            z-index: 111;

            .experience-title {
                font-size: $fs-24;
                font-weight: $semi-bold;
                color: $white;
                margin: 0;
                line-height: 30px;
            }
        }
    }
}


.our-training-box {
    background-color: $trainingCardBg;
    padding: 35px;
    border-radius: 20px;

    .our-training-content {
        @include flex(row, space-between, center);

        .our-training-leftside {
            max-width: 500px;
            @include flex(column, space-between, center, wrap);
            height: 100%;

            .our-training-title {
                font-size: $fs-36;
                font-weight: $semi-bold;
                color: $white;
                margin-bottom: 160px;
                position: relative;
                padding-left: 40px;

                .sr-count {
                    position: absolute;
                    left: 0;
                }
            }

            .training-desc {

                p {
                    color: $white-opacity-60;
                    font-size: $fs-20;
                    font-weight: $medium;
                    margin-bottom: 25px !important;
                }

                .list-view {
                    li {
                        @include flex(column, start, stretch, wrap);
                        color: $white;
                        font-size: $fs-16;
                        font-weight: $medium;
                        position: relative;
                        padding-left: 25px;

                        &:not(:last-child) {
                            margin-bottom: 15px;
                        }

                        &:before {
                            content: "\f26b";
                            font-family: $fontAwesome;
                            color: $orange;
                            position: absolute;
                            left: 0;
                        }
                    }
                }
            }
        }

        .our-training-picture {
            max-width: 640px;
        }
    }
}


.bh-about-training {
    .about-trainer-header {
        @include flex(row, space-between);

        .title-sec {
            max-width: 550px;

            &.w-690 {
                max-width: 695px;
            }
        }

        .about-trainer-header-img {
            max-height: 210px;
            max-width: 465px;
            overflow: hidden;
        }
    }

    .about-trainer-berif {
        @include flex(row, space-between);

        .trainer-profile {
            max-height: 415px;
            max-width: 935px;
            width: 935px;
        }

        p {
            color: $textLightGrey;
            font-size: $fs-20;
            font-weight: $medium;
            max-width: 450px;
            padding: 25px;
        }
    }
}


.training-philosopher {
    .training-philosopher-grid {
        .philosopher-book-list {
            padding-top: 35px;
            padding-bottom: 35px;

            &:not(:last-child) {
                border-bottom: 1px solid $border-grey;
            }

            .philosopher__no {
                background-color: $badgeDark;
            }

            .philosopher__desc {
                color: $textDarkGrey;
            }
        }
    }

}

.exce-training-corporate-page {

    .banner-title {
        @media (min-width:350px) and (max-width:1024px) {
            font-size: 45px !important;
            line-height: 60px !important;
        }
    }

    .banner-two {
        @media (min-width:350px) and (max-width:640px) {
            min-height: 700px !important;
        }
    }

    .bh-about-training {
        @media (min-width:350px) and (max-width:640px) {
            padding-top: 2 !important;
        }
    }

    .about-trainer-header {
        @media (min-width:350px) and (max-width:1024px) {
            flex-direction: column;
        }
    }

    .about-trainer-header-img {
        @media (min-width:350px) and (max-width:1024px) {
            height: 220px;
            width: 100% !important;
            max-width: 100% !important;
        }

        @media (min-width:1280px) and (max-width:1536px) {
            padding-left: 1rem !important;
        }

        img {
            @media (min-width:350px) and (max-width:1024px) {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .about-trainer-berif {
        @media (min-width:350px) and (max-width:1024px) {
            padding-top: 1rem;
            flex-direction: column;
        }
    }

    .title-heading {
        @media (min-width:350px) and (max-width:1024px) {
            font-size: 50px !important;
        }
    }

    .trainer-profile {
        @media (min-width:350px) and (max-width:1024px) {
            height: auto !important;
            width: auto !important;
        }
    }

    .about-trainer-berif {
        p {
            @media (min-width:350px) and (max-width:1024px) {
                padding: 1rem 0rem !important;
                width: 100% !important;
                max-width: 100% !important;
            }
        }
    }

    .bh-training-sec {
        @media (min-width:350px) and (max-width:1024px) {
            padding-top: 0 !important;
        }
    }

    .our-training-title {
        @media (min-width:350px) and (max-width:1024px) {
            font-size: 25px !important;
            margin-bottom: 30px !important;
        }
    }

    .our-training-content {
        @media (min-width:350px) and (max-width:640px) {
            flex-direction: column;
        }

        @media (min-width:768px) and (max-width:1024px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .our-training-leftside {
        @media (min-width:768px) and (max-width:1024px) {
            display: flex !important;
            flex-direction: column !important;
            align-items: flex-start !important;
        }
    }

    .our-training-picture {
        @media (min-width:350px) and (max-width:1024px) {
            padding-top: 1rem;
        }
    }

    .bh-experience {
        @media (min-width:350px) and (max-width:640px) {
            padding-top: 0rem;
        }
    }

    .bh-experience-header {
        @media (min-width:350px) and (max-width:640px) {
            flex-direction: column;
        }

        @media (min-width:768px) and (max-width:1024px) {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }

    .exce-training-corporate-get-in-touch {
        @media (min-width:350px) and (max-width:1024px) {
            height: 600px;
        }
    }

    .exce-get-in-inner-row {
        @media (min-width:350px) and (max-width:1024px) {
            height: 100%;
        }
    }

    .exce-get-in-inner-col {
        @media (min-width:350px) and (max-width:1024px) {
            height: 100%;
        }
    }

    .corporate-box {
        @media (min-width:350px) and (max-width:1024px) {
            height: 100%;
        }

        .event-image {
            @media (min-width:350px) and (max-width:1024px) {
                height: 100%;
            }

            img {
                @media (min-width:350px) and (max-width:1024px) {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .event-sub-title {
        @media (min-width:350px) and (max-width:1024px) {
            font-size: 40px !important;
            line-height: 50px !important;
        }
    }

    .exce-training-corporate-heading {
        @media (min-width:768px) and (max-width:1024px) {
            width: 100%;
            margin: 0 !important;
        }
    }

    .header-desc {
        @media (min-width:768px) and (max-width:1024px) {
            width: 100% !important;
            max-width: 100% !important;
        }
    }

    .title-sec {
        @media (min-width:768px) and (max-width:1024px) {
            width: 100% !important;
            max-width: 100% !important;
        }

        @media (min-width:1280px) {
            max-width: 695px !important;
        }
    }

    .exce-home-event-section {
        padding-top: 0 !important;
    }

    .exce-institute-phil-row {
        @media (min-width:768px) and (max-width:1024px) {
            display: flex !important;
            flex-direction: column !important;
        }
    }

    .exce-institute-phil-col {
        @media (min-width:768px) and (max-width:1024px) {
            width: 100%;
        }
    }

    .exce-institute-phil-detail {
        @media (min-width:768px) and (max-width:1024px) {
            width: 100%;
        }
    }

}