.exce-contactUs-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__inner {
    padding-top: 63px;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    background-color: black;
    width: 100%;
    height: 100%;

    .contact-upper-section {
      height: 120vh;

      .upper-section-left-img-div {
        width: 60%;
        height: 100%;
      }
    }

  }

}